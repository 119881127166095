import firestore from '../firebase/firestore'
import { firebaseAuth } from '../firebase/firebaseAuth'
const userRef = firestore.collection('users')

export const getUser = id => {
return userRef.doc(id).get()
}

export const setUser = (id, data) => {
return userRef.doc(id).set(data)
}

export const updateUser = (id, data) => {
    return userRef.doc(id).update(data)
}

export const getUsers = params => {
if (params && params.status) userRef.where('status', '==', params.status)
return userRef
}

export const createUser = (email, name, password, group) => {
    return firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            const uid = user.uid;
            const data = {
                id: uid,
                name,
                group: group,
                email,
                updated_at: new Date(),
                created_at: new Date()
            }
                return setUser(uid, data)
                    .then(() => {
                        return data
                    })
                    .catch(error => {
                        console.error(error)
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        return { errorCode, errorMessage}
                    })
          })
          .catch((error) => {
            console.error(error)
            const errorCode = error.code;
            const errorMessage = error.message;
            return { errorCode, errorMessage}
            // ..
          });
}

export const rememberPassword = (email) => {
    return firebaseAuth.sendPasswordResetEmail(email)
      .then(() => {
        return true
      })
      .catch(error => {
        console.error('Error: ', error)
        return error
      })
}