import { firebaseApp } from './firebase'
import 'firebase/firestore'

const firestore = firebaseApp.firestore()
firestore.settings({
    cacheSizeBytes: firestore.CACHE_SIZE_UNLIMITED,
})
firestore.enablePersistence()
// firestore.settings({ timestampsInSnapshots: true })
export default firestore
