import firestore from '../firebase/firestore'
const configRef = firestore.collection('config')

export const getConfig = () => {
    return configRef
        .get()
        .then(async (res) => {
            let config = {}
            await res.docs.map(c => {
                config[c.id] = c.data()
            })
            return config
        })
        .catch(err => {
            console.error(err)
            return err
        })
}

export const updateConfig = (id, data) => {
    return configRef
        .doc(id)
        .update(data)
        .then(async (res) => {
            return res
        })
        .catch(err => {
            console.error(err)
            return err
        })
}
