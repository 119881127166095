<template>
    <v-container
        fluid
        class="white"
        v-if="!loading"
    >
        <v-row
            no-gutters
            align="center"
            class="page pa-0 ma-0"
            v-if="error"
            >
                {{error}}
            </v-row>
            <v-row
                no-gutters
                align="center"
                class="page pa-0 ma-0"
                v-if="child"
            >
            <v-col
                cols="12"
                sm="12"
                md="12"
                class="text-center"
                align="center"
            >
                <v-img
                    :src="require('@/assets/img/logo.jpeg')"
                    class="my-3"
                    contain
                    height="200"
                />
                <h1>Olá! Sou {{child.gender === 'F' ? 'a' : 'o'}} {{child.name.split(' ')[0]}} 😄</h1>
                <br>
                Tenho {{child.age}} {{child.age > 1 ? 'aninhos':'ano'}} e tantos sonhos, tantas coisas para pedir para o Papai Noel e pra você... 
                só que no fundo ficarei muito feliz com os presentes especiais que você irá escolher para mim: 
                um <strong>brinquedo</strong> bem legal, uma <strong>roupa completa</strong> muito bonita, 
                uma <strong>{{child.gender === 'F' ? 'calcinha' : 'cueca'}}, meias</strong> e claro, 
                não esqueça de um <strong>calçado</strong> bem estiloso.
                <br><br>Se puder/quiser colocar mais coisas, será maravilhoso, pode ser material escolar, 
                guloseimas, uhmmmmmm, produtos de higiene pessoal e o que mais a sua criatividade 
                e coração desejarem. 💕 Capricha, tá!? 💕
                <br><br>
            </v-col>
            <v-row align="center" justify="center" v-if="child.photoUrl" class="pt-5">
                <v-img
                    :src="child.photoUrl"
                    max-width="400"
                    aling="center"
                />
            </v-row>
            <!-- <v-col
                cols="12"
                sm="12"
                md="12"
                class="d-none d-sm-flex"
                align-self="center"
            >
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
                class="d-none d-sm-flex"
                align-self="center"
            >
                <v-img
                    :src="child.photoUrl"
                    widtt="130"
                />
            </v-col> -->
        </v-row>
    </v-container>
</template>
<script>
import { getChildren } from '../../services/children'
export default {
    data() {
        return {
            loading: false,
            children: null,
            child: null,
            error: null
        }
    },
    mounted() {
        if(this.$route.params.code) {
            this.loading = true
            let params = {
                id: this.$route.params.code
            }
            getChildren(params).get()
                .then(children => {
                    this.child = {
                        id: children.id,
                        ...children.data()
                    }
                    // if(children.size > 0) {
                    //     this.child = children.docs[0].data()
                    // } else {
                    //     this.error = 'Criança não encontrada!'
                    // }
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.error = err
                    this.loading = false
                })
        } else {
            this.error = 'Criança não encontrada!'
        }
    }
}
</script>