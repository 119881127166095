export default {
    firstName(state) {
        if(!state.mother) return ''
        let firstname = state.mother.name.split(' ')
        return firstname[0].charAt(0).toUpperCase() + firstname[0].slice(1);
    },
    totalMothers(state) {
        if(state.mothers && state.mothers.length > 0) {
            let total = state.mothers.reduce((sum, cur) => {
                if(cur.status !== 'desativado') return sum + 1
                else return sum
            },0)
            return total
        } else return 0
    },
    getDrawnMothers(state) {
        if(state.mothers) {
            return state.mothers.filter((el) => {
                // return el.status === 'sorteada' || el.status === 'sorteada sitio'
                return el.drawn === true || (Array.isArray(el.drawn) && el.drawn.includes(state.selectedYear))
            });
        } else return state.mothers
    },
    
    getBagDrawnMothers(state) {
        if(state.mothers?.length > 0) {
            return state.mothers.filter((el) => {
                return el.drawnBag === true  || (Array.isArray(el.drawnBag) && el.drawnBag.includes(state.selectedYear))
            });
        } else return state.mothers
    },
    getBasketDrawnMothers(state) {
        if(state.mothers) {
            return state.mothers.filter((el) => {
                return el.drawnBasket === true
                // return el.status === 'cesta basica'
            });
        } else return state.mothers
    },
    getPrayDrawnMothers(state) {
        if(state.mothers) {
            return state.mothers.filter((el) => {
                return el.drawnPray === true
                // return el.status === 'evangelização'
            });
        } else return state.mothers
    },
    getDrawnChildren(state) {
        if(state.sons) {
            return state.sons.filter((el) => {
                return el.status === 'aprovado'
            });
        } else return state.sons
    },
    totalSonsDrawed(state) {
        if(!state.mothers) return 0
        let total = state.mothers.reduce((sum, cur) => {
            if(cur.status === 'sorteada' || cur.status === 'sorteada sitio' ) return sum + Number(cur.sons)
            else return sum
        },0)
        return total
    },
    totalSonsBagDrawed(state) {
        if(!state.mothers) return 0
        let total = state.mothers.reduce((sum, cur) => {
            if(cur.status === 'sorteada sacolinha') return sum + Number(cur.sons)
            else return sum
        },0)
        return total
    },
    totalSons(state) {
        if(!state.mothers) return 0
        let total = state.mothers.reduce((sum, cur) => {
            if(cur.status !== 'desativado') return sum + Number(cur.sons)
            else return sum
        },0)
        return total
    },
    totalSonsCad(state) {
        return state.sons ? state.sons.length : 0
    },
    getConfigSubscribe(state) {
        return state.config.subscribe
    },
    inactivatedSubscribe(state) {
        return state.config && state.config.subscribe ? state.config.subscribe.activated : false
    },
    totalChildren(state) {
        if(state.childrenList && state.childrenList.length > 0) {
            let total = state.childrenList.reduce((sum, cur) => {
                if(cur.status !== 'desativado') return sum + 1
                else return sum
            },0)
            return total
        } else return 0
    },
    totalChildrenFinished(state) {
        if(state.childrenList && state.childrenList.length > 0) {
            let total = state.childrenList.reduce((sum, cur) => {

                if(cur.status === 'aprovado') return sum + 1
                else return sum
            },0)
            return total
        } else return 0
    },
    getChildrenByStatus(state, filter) {
        if(state.childrenList) {
            return state.childrenList.filter((el) => {
                return filter.indexOf(el.status) > -1
            })
        } else return state.childrenList
    },
}