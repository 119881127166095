// https://vuex.vuejs.org/en/state.html
const getDefaultState = () => {
    return {
      idToken: null,
      mother: null,
      currentUser: null,
      drawer: true,
      mothers: [],
      childrenList: null,
      sons: null,
      users: null,
      config: null,
      defaultYearParty: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      loading: false
    }
  }
  
  // initial state
  const state = getDefaultState()
  
  export default {
    getDefaultState,
    ...state
  }