export default {
    firebase: {
        apiKey: process.env.VUE_APP_FB_apiKey,
        authDomain: process.env.VUE_APP_FB_authDomain,
        databaseURL: process.env.VUE_APP_FB_databaseURL,
        projectId: process.env.VUE_APP_FB_projectId,
        storageBucket: process.env.VUE_APP_FB_storageBucket,
        messagingSenderId: process.env.VUE_APP_FB_messagingSenderId,
    },
}
