// https://vuex.vuejs.org/en/state.html
const getDefaultState = () => {
    return {
      mother: null,
      mothers: null,
      children: null,
      child: null,
    }
  }
  
  // initial state
  const state = getDefaultState()
  
  export default {
    getDefaultState,
    ...state
  }