import { getMothers, getMother, updateMother } from '../../../services/mothers'
import { getChildren, setChild, updChild, getChildrenByDoc } from '../../../services/children'

export default {
  clearState({commit}) {
    commit('resetState')
  },
  async getMothers({commit}, params) {
    return new Promise((resolve, reject) => {
      let mothersList = []
      return getMothers(params)
        .get()
        .then((mothers) => {
          mothersList = mothers.docs.map(mother => {
            return {id: mother.id, ...mother.data()}
          })
          commit('setMothers', mothersList)
          if(mothersList.length === 1) commit('setMother', mothersList[0])
          resolve(mothersList)
        })
        .catch(err =>{
          reject(err)
        })
      })
  },
  async getMother({commit}, id) {
    return new Promise((resolve, reject) => {
      return getMother(id)
        .then((mother) => {
          if(mother.exists) {
            let m = {id: mother.id, ...mother.data()}
            commit('setMother', m)
            resolve(m)
          } else {
            reject({ error: 'notfound' })
          }
        })
        .catch(err =>{
          reject(err)
        })
      })
  },
  updateMother({commit, state}, data) {
    return new Promise((resolve, reject) => {
      return updateMother(data.id, data)
        .then(() => {
          let mother = state.mother
          mother = {
            ...mother,
            ...data
          }
          commit('setMother', mother)
          resolve(mother)
        })
        .catch(err =>{
          reject(err)
        })
    })
  },
  addChild({commit}, data) {
    return new Promise((resolve, reject) => {
      return setChild(data)
        .then((docRef) => {
          let child = {
            id: docRef.id,
            ...data
          }
          commit('setChild', child)
          resolve(child)
        })
        .catch(err =>{
          reject(err)
        })
    })
  },
  updateChild({commit}, data) {
    return new Promise((resolve, reject) => {
      return updChild(data.id, data)
        .then((docRef) => {
          let child = {
            id: docRef.id,
            ...docRef.data()
          }
          commit('setChild', child)
          resolve(child)
        })
        .catch(err =>{
          reject(err)
        })
    })
  },
  async getChildrenByParams({commit}, params) {
    return new Promise((resolve) => {
      let childrenList = []
      return getChildren(params)
      .onSnapshot(async res => {
      const changes = res.docChanges();
        await changes.forEach((change) => {
          const { doc } = change
          switch (change.type) {
            case 'added': {
              const data = {
                  id: doc.id,
                  ...doc.data(),
              }
              childrenList.push(data)
              break
            }
            case 'modified': {
              const data = {
                  id: doc.id,
                  ...doc.data(),
              }
              // eslint-disable-next-line no-shadow
              const i = childrenList.findIndex(i => i.id === doc.id)
              childrenList.splice(i,1,data)
              break
            }
            case 'removed': {
              childrenList = childrenList.filter(i => i.id !== doc.id)
              break
            }
            default:
              break
          }
        })
        await commit('setChildren', childrenList)
        resolve(childrenList)
      })
    })
  },
  async getChildrenByDocument({commit}, document) {
    return new Promise((resolve, reject) => {
      return getChildrenByDoc(document)
        .then((children) => {
          if(children !== null) commit('setChildren', children)
          resolve(children)
        })
        .catch(err =>{
          reject(err)
        })
      })
  },
}