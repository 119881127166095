import Vue from 'vue'
import VueRouter from 'vue-router'
import { firebaseAuth } from '../firebase/firebaseAuth'
import Home from '../views/Home.vue'
import Letter from '../views/children/Letter.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/admin/Login.vue'),
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: '/lembrar-senha',
    name: 'RememberPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/admin/RememberPassword.vue'),
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: '/',
    name: 'Cadastro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Subscribe.vue'),
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: '/admin/cestabasica',
    name: 'Cestas Básicas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/CestaBasica.vue'),
    meta: {
      requiresAuth: true,
      requiresGroup: ['admin']
    }
  },
  {
    path: '/cadastro-realizado',
    name: 'Cadastro Concluido',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SubscribeSucess.vue'),
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: '/cadastro/filhos',
    name: 'CadastroCrianca',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/children/Subscribe.vue'),
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: '/cadastro/filhos/:id',
    name: 'CadastroCriancaID',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/children/Subscribe.vue'),
    meta: {
      fullpage: true,
      public: true
    }
  },
  {
    path: '/admin/',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Home.vue'),
    meta: {
      requiresAuth: true,
      requiresGroup: ['admin', 'customer_care']
    }
  },
  {
    path: '/admin/maes',
    name: 'Mothers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/MotherList.vue'),
    meta: {
      requiresAuth: true,
      requiresGroup: ['admin']
    }
  },
  {
    path: '/admin/criancas',
    name: 'Childrens',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ChildrenList.vue'),
    meta: {
      requiresAuth: true,
      requiresGroup: ['admin']
    }
  },
  {
    path: '/admin/colaboradores',
    name: 'Colaboradores',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserList.vue'),
    meta: {
      requiresAuth: true,
      requiresGroup: ['admin']
    }
  },
  {
    path: '/admin/geral',
    name: 'Geral',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Config.vue'),
    meta: {
      requiresAuth: true,
      requiresGroup: ['admin']
    }
  },
  {
    path: '/admin/configuracao/inscricao',
    name: 'ConfigInscricao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/admin/config/SubscribeForm.vue'),
    meta: {
      requiresAuth: true,
      requiresGroup: ['admin']
    }
  },
  {
    path: '/admin/criancas/etiquetas',
    name: 'Etiquetas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/HangTags.vue'),
    meta: {
      requiresAuth: true,
      requiresGroup: ['admin']
    }
  },
  {
    path: '/c/:code',
    name: 'Carta',
    component: Letter,
    meta: {
      fullpage: true,
      public: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebaseAuth.currentUser
  const logedUser = JSON.parse(localStorage.getItem('currentUser'))
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const publicRoute = to.matched.some(record => record.meta.public)
  const requiresGroup = to.matched.some(record => record.meta.requiresGroup)
  if (publicRoute) next()
  else if (requiresAuth && currentUser) {
    if(!logedUser) {
      next('/login')
    }
    if(requiresGroup) {
      let isAccess = 0;
      if(typeof(logedUser.group) === 'string') {
        isAccess = to.meta.requiresGroup.indexOf(logedUser.group);
      } else if(Array.isArray(logedUser.group)) {
        isAccess = to.meta.requiresGroup.some(r=> logedUser.group.indexOf(r)) //ES6
      }
      if (isAccess >= 0){
        next()
      } else {
          next('/login')
      }
    } else {
      next()
    }
  }
  else if (requiresAuth && !currentUser) next('/login')
  else next('/login')
})

export default router
