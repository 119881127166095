import { set } from '@/utils/vuex'
export default {
    resetState (state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, state.getDefaultState())
    },
    setMother(state, data) {
        state.mother = data
    },
    setMothers: set('mothers'),
    setChildren: set('children'),
    setChild: set('child'),
}