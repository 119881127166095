// https://vuex.vuejs.org/en/state.html
const getDefaultState = () => {
    return {
      beneficiado: null,
      beneficiados: null,
    }
  }
  
  // initial state
  const state = getDefaultState()
  
  export default {
    getDefaultState,
    ...state
  }