// https://vuex.vuejs.org/en/actions.html
import { firebaseAuth } from '@/firebase/firebaseAuth'
// import axios from 'axios'
import router from '../router'

import { getMother, getMothers, getMotherByDoc } from '../services/mothers'
import { getChildren } from '../services/children'
import { getUsers } from '../services/users'
import { getConfig, updateConfig } from '../services/config'

export default {
  setLogoutTimer ({commit}, expirationTime) {
    setTimeout(() => {
      commit('clearAuthData')
      router.replace('/login')
    }, expirationTime * 1000)
  },
  logout ({commit}) {
    firebaseAuth
      .signOut()
      .then(() => {
        localStorage.removeItem('userId')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('expirationDate')
        commit('resetState')
        router.replace('/login')
      })
      .catch((error) => {
        console.error(`SingOut Error: ${error}`)
      })
  },
  async login({commit, dispatch}, authData) {
    return await firebaseAuth.signInWithEmailAndPassword(authData.email, authData.password)
      .then(res => {
        return res.user.getIdToken().then(token => {
          commit('setCurrentUser', {
            idToken: token,
            userId: res.user.uid
          })
          dispatch('setLogoutTimer', 2500)
          const now = new Date()
          const expirationDate = new Date(now.getTime() + (2500 * 1000)) //Configura o logout para daqui 1 hora
          localStorage.setItem('token', token)
          localStorage.setItem('userId', res.user.uid)
          localStorage.setItem('expirationDate', expirationDate)
          // dispatch('setConfig')
          return res
        })
      })
      .catch(error => {
        console.error('Error: ', error)
        return error
      })
  },
  async getConfig({commit}) {
    return new Promise((resolve, reject) => {
      getConfig()
        .then((config) => {
          commit('setConfig', config)
          resolve(config)
        })
        .catch(err => {
          console.error(err)
          reject(err)
        })
    })
  },
  updateConfig({state, commit}, params) {
    return new Promise((resolve, reject) => {
      updateConfig(params.id, params.data)
        .then(() => {
          let config = state.config
          config[params.id] = params.data
          commit('setConfig', config)
          resolve(config)
        })
        .catch(err => {
          console.error(err)
          reject(err)
        })
    })
  },
  async getMothers({commit}, params) {
    commit('setMothers', [])
    return new Promise((resolve) => {
      let mothersList = []
      // getMothersByYear(2021)
      return getMothers(params).onSnapshot(async res => {
        const changes = res.docChanges();
        await changes.forEach((change) => {
          const { doc } = change
          switch (change.type) {
            case 'added': {
              const data = {
                  id: doc.id,
                  ...doc.data(),
              }
              // mothersList.push(data)
              commit('pushMothers', data)
              break
            }
            case 'modified': {
              const data = {
                  id: doc.id,
                  ...doc.data(),
              }
              // eslint-disable-next-line no-shadow
              // mothersList.splice(i,1,data)
              commit('spliceMother', data)
              break
            }
            case 'removed': {
              // mothersList = mothersList.filter(i => i.id !== doc.id)
              commit('deleteMother', doc.id)
              break
            }
            default:
              break
          }
        })
        // await commit('setMothers', mothersList)
        resolve(mothersList)
      })
    })
  },
  async getMothersByToken({commit}, params) {
    return new Promise((resolve, reject) => {
      let mothersList = []
      return getMothers(params)
        .get()
        .then((mothers) => {
          commit('setMothers', mothersList)
          resolve(mothers)
        })
        .catch(err =>{
          reject(err)
        })
      })
  },
  async getMothersByDocument({commit}, document) {
    return new Promise((resolve, reject) => {
      return getMotherByDoc(document)
        .then((mother) => {
          if(mother !== null) commit('setMother', mother)
          resolve(mother)
        })
        .catch(err =>{
          reject(err)
        })
      })
  },
  async getUsers({commit}, params) {
    return new Promise((resolve) => {
      let usersList = []
      return getUsers(params)
      .onSnapshot(async res => {
        const changes = res.docChanges();
        await changes.forEach((change) => {
          const { doc } = change
          switch (change.type) {
            case 'added': {
              const data = {
                  id: doc.id,
                  ...doc.data(),
              }
              usersList.push(data)
              break
            }
            case 'modified': {
              const data = {
                  id: doc.id,
                  ...doc.data(),
              }
              // eslint-disable-next-line no-shadow
              const i = usersList.findIndex(i => i.id === doc.id)
              usersList.splice(i,1,data)
              break
            }
            case 'removed': {
              usersList = usersList.filter(i => i.id !== doc.id)
              break
            }
            default:
              break
          }
        })
        await commit('setUsers', usersList)
        resolve(usersList)
      })
    })
  },
  async getChildren({commit}, params) {
    return new Promise((resolve) => {
      let childrenList = []
      return getChildren(params)
      .onSnapshot(async res => {
      const changes = res.docChanges();
        await changes.forEach((change) => {
          const { doc } = change
          switch (change.type) {
            case 'added': {
              const data = {
                  id: doc.id,
                  ...doc.data(),
              }
              childrenList.push(data)
              break
            }
            case 'modified': {
              const data = {
                  id: doc.id,
                  ...doc.data(),
              }
              // eslint-disable-next-line no-shadow
              const i = childrenList.findIndex(i => i.id === doc.id)
              childrenList.splice(i,1,data)
              break
            }
            case 'removed': {
              childrenList = childrenList.filter(i => i.id !== doc.id)
              break
            }
            default:
              break
          }
        })
        await commit('setChildrenList', childrenList)
        resolve(childrenList)
      })
    })
  },
  async getChildrenAndMother({commit}, params) {
    commit('setChildrenList', [])
    return new Promise((resolve) => {
      commit('setLoading', true)
      let childrenList = []
      return getChildren(params)
      .onSnapshot(async res => {
        const changes = res.docChanges();
          await changes.forEach(async (change) => {
            const { doc } = change
            switch (change.type) {
              case 'added': {
                const data = {
                    id: doc.id,
                    ...doc.data(),
                }
                // Pega a dados da mãe
                let mother = await getMother(data.mother_id)
                if(mother.exists) {
                  data.mother_name = mother.data().name
                  data.mother = mother.data()
                }
                commit('pushChildrenList', data)
                // childrenList.push(data)
                break
              }
              case 'modified': {
                const data = {
                    id: doc.id,
                    ...doc.data(),
                }
                // Pega a dados da mãe
                let mother = await getMother(data.mother_id)
                if(mother.exists) {
                  data.mother_name = mother.data().name
                  data.mother = mother.data()
                }
                // eslint-disable-next-line no-shadow
                commit('spliceChildrenList', data)
                // const i = childrenList.findIndex(i => i.id === doc.id)
                // childrenList.splice(i,1,data)
                break
              }
              case 'removed': {
                commit('deleteChildrenList', doc.id)
                // childrenList = childrenList.filter(i => i.id !== doc.id)
                break
              }
              default:
                break
            }
          })
          // await commit('setChildrenList', childrenList)
          commit('setLoading', false)
          resolve(childrenList)
        })
    })
  },
  setSelectYear({commit, dispatch}, param) {
    commit('setSelectedYear', param)
    if(param === 'Cesta Básica') {
      dispatch('getMothers', {drawnBasket: true})
      dispatch('getChildrenAndMother', {drawnBasket: true})
    } else {  
      dispatch('getMothers', {
        "yearParty": param
      })
      dispatch('getChildrenAndMother', {
        "yearParty": param
      })
      }
  }
}