<template>
  <!-- <hello-world /> -->
  <count-down />
</template>

<script>
  // import CountDown from '../components/countdown/Countdown'
  // import HelloWorld from '../components/HelloWorld'
  export default {
    name: 'Home',

    components: {
      // HelloWorld
      // CountDown,
    },
  }
</script>
