<template>
  <v-app>
    <v-card flat v-if="!loading" class="pt-0 mt-0">
      <drawer v-if="$route.meta.fullpage !== true"/>
      <app-bar v-if="$route.meta.fullpage !== true"/>
      
      <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid v-if="$route.meta.fullpage !== true">

          <!-- If using vue-router -->
          <router-view></router-view>
        </v-container>
        <router-view v-else></router-view>
      </v-main>
      <v-footer app v-if="$route.meta.fullpage !== true" color="#efefef">
        <div class="text--center">Lar Ismael @ 21</div>
      </v-footer>
    </v-card>
    <v-container fill-height fluid v-else>
        <v-row align="center"
      justify="center" style="height:100vh">
          <v-img
            src="@/assets/img/logo.jpeg"
            max-width="150px"
            max-height="150px"
            class="align-center justify-center center"
          />
        </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { firebaseAuth } from './firebase/firebaseAuth'
import { getUser } from './services/users';

export default {
  name: 'App',
  components: {
    Drawer: () => import('./components/core/Drawer.vue'),
    AppBar: () => import('./components/core/AppBar.vue')
  },
  async mounted() {
    this.loading = true
    if(!this.currentUser) {
      const currentUser = firebaseAuth.currentUser
      if(currentUser && currentUser.uid) {
        await getUser(currentUser.uid)
          .then((user) => {
            this.$store.commit('setCurrentUser', {id: user.id, ...user.data()})
          })
          .catch(err => {
            console.error('Erro ao pegar usuário logado! ', err)
            this.$router.push("/login")
          })
      }
    }
    await this.getConfig()
    this.loading = false
  },
  data: () => ({
    //
    loading: false,
  }),
  computed: {
    ...mapState(['currentUser'])
  },
  methods: {
    ...mapActions(['getConfig'])
  }
};
</script>
