import firestore from '../firebase/firestore'
let motherRef = firestore.collection('mothers')
import { getChildrenListByMother} from './children'

export const getMother = id => {
    return motherRef.doc(id).get()
}

export const isMotherDuplicated = (rg, yearParty) => {
    return motherRef.where('document', '==', rg).where('yearParty', '==', yearParty).get()
        .then((doc => {
            if(doc.size > 0) return true
            else return false
        }))
        .catch(err => {
            console.error(err)
            return false
        })
}

export const setMother = data => {
    return motherRef.add(data)
}

export const updateMother = (id, data) => {
    return motherRef.doc(id).update(data)
}

export const getMothers = params => {
    let mothersRef = firestore.collection('mothers')
    if (params && params.status) 
        mothersRef = mothersRef.where('status', '==', params.status)
    
    if (params && params.token) 
        mothersRef = mothersRef.where('token', '==', params.token)
    
    if (params && params.yearParty) {
        mothersRef = mothersRef.where('year', 'array-contains', params.yearParty)
    }

    if (params && params.drawnBasket) {
        mothersRef = mothersRef.where('drawnBasket', '==', params.drawnBasket)
    }
    if(params && params.document) {
        let result = params.document.replace(/[\W]/g,'').toUpperCase()
        mothersRef = mothersRef.where('documentFormatted ', '==', result)
    }
    return mothersRef
}

export const getMotherByDoc = async doc => {
    let mothersRef = firestore.collection('mothers')
    let mothers = await mothersRef.where('documentFormatted', '==', doc).get()
    console.log(mothers)
    if(mothers.size > 0) {
        return { id: mothers.docs[0].id ,...mothers.docs[0].data() }
    } else {
        return null
    }
}


export const getMothersAndChildrenTag = () => {
    return new Promise((resolve, reject) => {
        let list = []
        motherRef
            .where('drawn', '==', true)
            .limit(10)
            .orderBy('order', 'asc')
            .get()
            .then(async (mothers) => {
                if(!mothers || mothers.size <= 0) {
                    console.error('Erro ao buscar mães. ')
                    reject(false)
                }
                for(let i=0; i < mothers.size; i++) {
                    let mother = {
                        id: mothers.docs[i].id,
                        ...mothers.docs[i].data()
                    }
                    await getChildrenListByMother(mother)
                        .then(child => {
                            list.push(child)
                        })
                        .catch(err => {
                            console.error('Erro ao buscar filhos. ', err)
                            reject(err)    
                        })
                }
                resolve(list)
            })
            .catch(err => {
                console.error('Erro ao buscar mães. ', err)
                reject(err)    
            })
    })
}

export const validaTokenDup = (token, year) => {
    return new Promise(resolve => {
        let ref = firestore.collection('mothers')
        ref = ref.where('token', '==', token)
        ref = ref.where('yearParty', '==', year)
        ref.get()
            .then((res) => {
                if(res.size > 0) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch((err) => {
                console.error(err)
                resolve(true)
            })
    })
}