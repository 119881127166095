import { set, toggle } from '@/utils/vuex'
export default {
    resetState (state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, state.getDefaultState())
    },
    setMother(state, data) {
        state.mother = data
    },
    setCurrentUser(state, data) {
        state.currentUser = data
    },
    setMothers: set('mothers'),
    setChildrenList: set('childrenList'),
    setSons: set('sons'),
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
    setUsers: set('users'),
    setConfig: set('config'),
    setLoading: set('loading'),
    setonfigSubscribe(state, data) {
        state.config.subscribe = data
    },
    setDefaultYearParty: set('defaultYearParty'),
    setSelectedYear: set('selectedYear'),
    spliceMother(state, doc) {
        const i = state.mothers.findIndex(i => i.id === doc.id)
        state.mothers.splice(i,1,doc)
    },
    deleteMother(state, id) {
        const i = state.mothers.findIndex(i => i.id === id)
        state.mothers.splice(i,1)
    },
    pushMothers(state, doc) {
        state.mothers.push(doc)
    },
    pushChildrenList(state, doc) {
        state.childrenList.push(doc)
    },
    spliceChildrenList(state, doc) {
        const i = state.childrenList.findIndex(i => i.id === doc.id)
        state.childrenList.splice(i,1,doc)
    },
    deleteChildrenList(state, id) {
        const i = state.childrenList.findIndex(i => i.id === id)
        state.childrenList.splice(i,1)
    }
}