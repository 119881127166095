export default {
    firstName(state) {
        if(!state.mother) return ''
        let firstname = state.mother.name.split(' ')
        return firstname[0].charAt(0).toUpperCase() + firstname[0].slice(1);
    },
    totalBeneficiados(state) {
        if(state.beneficiados && state.beneficiados.length > 0) {
            let total = state.beneficiados.reduce((sum, cur) => {
                if(cur.status !== 'desativado') return sum + 1
                else return sum
            },0)
            return total
        } else return 0
    },
}