import firestore from '../firebase/firestore'

export const getChild = id => {
    return firestore.collection('children').doc(id).get()
}

export const isChildDuplicated = rg => {
    let childrenRef = firestore.collection('children')

    return childrenRef.where('document', '==', rg).get()
        .then((doc => {
            if(doc.size > 0) return true
            else return false
        }))
        .catch(err => {
            console.error(err)
            return false
        })
}

export const setChild = data => {
    return firestore.collection('children').add(data)
}

export const updChild = (id, data) => {
    let ref = firestore.collection('children').doc(id)
    return ref.update(data)
            .then(() => {
                return ref.get()
            })
            .catch(err => err)
}

export const getChildren = params => {
    let childrenRef = firestore.collection('children')
    if (params && params.id) 
        childrenRef = childrenRef.doc(params.id)

    if (params && params.status) 
        childrenRef = childrenRef.where('status', '==', params.status)
    
    if (params && params.token) 
        childrenRef = childrenRef.where('token', '==', params.token)

    if(params && params.code)
        childrenRef = childrenRef.where('code', '==', params.code)

    if(params && params.mother_id)
        childrenRef = childrenRef.where('mother_id', '==', params.mother_id)

    // if(params && params.yearParty)
    //     childrenRef = childrenRef.where('yearParty', '==', params.yearParty)

    if (params && params.yearParty) {
        childrenRef = childrenRef.where('year', 'array-contains', params.yearParty)
    }

    if(params && params.year) {
        childrenRef = childrenRef.where('year', 'array-contains', params.year)
    }

    if(params && params.drawnBasket)
        childrenRef = childrenRef.where('drawnBasket', '==', params.drawnBasket)
    
    return childrenRef
}

export const getChildrenList = async (list) => {
    let motherRef = firestore.collection('mothers')
    
    return new Promise((resolve, reject) => {
        if(!list || list.length < 0) {
            reject({errro: 'Lista Vazia'})
        }
        let newList = []
        for(let i=0; i < list.length; i++) {
            let children = list[i]
            motherRef.doc(children.mother_id).get()
                .then(mother => {
                    let newChildrenList = {
                        ...children,
                        mother: mother.data()
                    }
                    newList.push(newChildrenList)
                })
                .catch(err => { 
                    console.error(err) 
                    return
                })
        }
        resolve(newList)
    })
}

export const getChildrenListByMother = async (mother) => {
    let childrenRef = firestore.collection('children')
    return new Promise((resolve, reject) => {
        if(!mother || !mother.id) {
            reject({errro: 'Mãe nao informada'})
        }
        let list = []
        childrenRef.where('mother_id', '==', mother.id).get()
            .then(children => {
                if(!children || children.size <= 0) {
                    console.error('Erro ao buscar mães. ')
                    reject(false)
                }
                for(let i=0; i < children.size; i++) {
                    let child = {
                        id: children.docs[i].id,
                        ...children.docs[i].data(),
                        mother: mother,
                    }
                    list.push(child)
                }
                resolve(list)
            })
            .catch(err => { 
                console.error(err) 
                return
            })
    })
}
export const getChildrenByDoc = async doc => {
    let childrenRef = firestore.collection('children')
    let children = await childrenRef.where('documentFormatted', '==', doc).get()
    console.log(children)
    if(children.size > 0) {
        return { id: children.docs[0].id ,...children.docs[0].data() }
    } else {
        return null
    }
}