import { getBeneficiados, updateBeneficiado, getBeneficiado } from '../../../services/beneficiados'

export default {
  clearState({commit}) {
    commit('resetState')
  },
  async getBeneficiadosByParam({commit}, params) {
    return new Promise((resolve, reject) => {
      let beneficiadosList = []
      return getBeneficiados(params)
        .get()
        .then((beneficiados) => {
          if(beneficiados.size <= 0) resolve(beneficiadosList)
          beneficiadosList = beneficiados.docs.map(benefificado => {
            return {id: benefificado.id, ...benefificado.data()}
          })
          commit('setBeneficiados', beneficiadosList)
          if(beneficiadosList.length === 1) commit('setBeneficiado', beneficiadosList[0])
          resolve(beneficiadosList)
        })
        .catch(err =>{
          reject(err)
        })
      })
  },
  async getBeneficiado({commit}, id) {
    return new Promise((resolve, reject) => {
      return getBeneficiado(id)
        .then((beneficiado) => {
          if(beneficiado.exists) {
            let m = {id: beneficiado.id, ...beneficiado.data()}
            commit('setBeneficiado', m)
            resolve(m)
          } else {
            reject({ error: 'notfound' })
          }
        })
        .catch(err =>{
          reject(err)
        })
      })
  },
  updateBeneficiado({commit, state}, data) {
    return new Promise((resolve, reject) => {
      return updateBeneficiado(data.id, data)
        .then(() => {
          let beneficiado = state.beneficiado
          beneficiado = {
            ...beneficiado,
            ...data
          }
          commit('setBeneficiado', beneficiado)
          resolve(beneficiado)
        })
        .catch(err =>{
          reject(err)
        })
    })
  },
}