import firestore from '../firebase/firestore'
let beneficiadoRef = firestore.collection('beneficiados')

export const getBeneficiado = id => {
    return beneficiadoRef.doc(id).get()
}

export const setBeneficiado = data => {
    return beneficiadoRef.add(data)
}

export const updateBeneficiado = (id, data) => {
    return beneficiadoRef.doc(id).update(data)
}

export const getBeneficiados = params => {
    let beneficiadoRef = firestore.collection('beneficiados')
    if (params && params.status) 
        beneficiadoRef = beneficiadoRef.where('status', '==', params.status)
    
    if (params && params.token) 
        beneficiadoRef = beneficiadoRef.where('token', '==', params.token)
       
    return beneficiadoRef
}